import React, { useState, useEffect } from "react";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import "./newsWidget.css";
import { ReactComponent as LoadingSpinner } from "../../assets/loading.svg";

const API_KEY = process.env.REACT_APP_NEWS_API_KEY;
const API_URL = `https://serpapi.com/search?engine=google_news&gl=pt&api_key=${API_KEY}`;
console.log(API_URL);
const NewsWidget = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(API_URL);
        setNews(response.data.news_results);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading)
    return (
      <div className="loading d-flex bg-dark flex-column  h-100 w-100 overflow-hidden rounded-4">
        <LoadingSpinner />
      </div>
    );
  if (error)
    return (
      <div className="loading text-white d-flex bg-dark flex-column  h-100 w-100 overflow-hidden rounded-4">
        Error fetching news: {error.message}
      </div>
    );
  return (
    <Carousel>
      {news.map((item, index) => (
        <Carousel.Item key={index}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <img
              className="d-block w-100"
              src={item.thumbnail}
              alt={item.title}
            />
            <Carousel.Caption>
              <h3>{item.title}</h3>
              <p>
                <img
                  src={item.source.icon}
                  alt={item.source.name}
                  className="news-source-icon"
                />
                {item.source.name}
              </p>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default NewsWidget;
